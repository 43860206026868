// Desc: Profile page
// Path: src/Members.js

import React from "react";

import WebService from '../Service.js';

import {PageTitle, StatTile, ElementTile, BackgroundText, MoneyText}  from "./Utils.js";

import UserIcon from "../images/utilisateur.png";
import Balance from "../images/balance.png";
import euro from "../images/euro.png";

import Utils from "../Utils.js"

import loader from "../images/loader.gif";



// CSS
import "../App.css";




export default
class Members extends React.Component {
    constructor(props) {
        super(props);
        let currentYear = new Date().getFullYear();
        this.state = {
            members: null,
            membersFiltered: null,
            currentYear: currentYear,
            selectedMember: null,
            loaded: false,
            currentlyEditedWalletRow: null,
            rowEditionDescription: "",
            rowEditionAmount: 0,
            rowEditionValidation: false,
            totalCotisant: 0,
            sommeCagnotte: 0,
            sommeCagnotteEnAttente: 0,
        };
    }

    componentDidMount() {
        WebService.users().then((members) => {
            members.json().then((members_data) => {
                this.setState({members: members_data});
                this.setState({membersFiltered: members_data});

                let new_members_data = [];
                let incr = 0;
                let cotisants = 0;
                let sommeCagnotte = 0;
                let sommeCagnotteEnAttente = 0;
                members_data.forEach((member) => {
                    WebService.walletHistory(member.id).then((wallet) => {
                        wallet.json().then((wallet_data) => {

                            member.wallet = wallet_data;
                            member.solde = 0;
                            member.creances = 0;
                            if(wallet_data.length > 0) {
                                wallet_data.forEach((wallet_item) => {
                                    if(wallet_item.validated) {
                                        member.solde += wallet_item.amount;
                                        sommeCagnotte += wallet_item.amount;
                                    } else {
                                        member.creances += wallet_item.amount;
                                        sommeCagnotteEnAttente += wallet_item.amount;
                                    }
                                });
                            }

                            new_members_data.push(member);

                            incr++;
                            if(incr === members_data.length) {
                                this.setState({members: new_members_data});
                                this.setState({membersFiltered: new_members_data});
                                let new_members_data_fee = [];
                                new_members_data.forEach((member) => {
                                    WebService.feeHistory(member.id).then((fee) => {
                                        fee.json().then((fee_data) => {
                                            member.fee = fee_data;

                                            let isCurrentYearPaid = false;
                                            fee_data.forEach((fee_item) => {
                                                if(parseInt(fee_item.year) === this.state.currentYear) {
                                                    isCurrentYearPaid = true;
                                                }
                                            });
                                            member.isCurrentYearPaid = isCurrentYearPaid;
                                            if(isCurrentYearPaid) cotisants++;
                                            new_members_data_fee.push(member);
                                            if(new_members_data_fee.length === new_members_data.length) {
                                                this.setState({members: new_members_data_fee});
                                                this.setState({membersFiltered: new_members_data_fee});
                                                this.setState({loaded: true});
                                                this.setState({totalCotisant: cotisants});
                                                this.setState({sommeCagnotte: sommeCagnotte});
                                                this.setState({sommeCagnotteEnAttente: sommeCagnotteEnAttente});

                                                // Calculate the total amount of the wallet
                                                /*
                                                let sommeCagnotte = 0;
                                                let sommeCagnotteEnAttente = 0;
                                                new_members_data_fee.forEach((member) => {
                                                    member.wallet.forEach((wallet_item) => {
                                                        if(wallet_item.validated) {
                                                            sommeCagnotte += wallet_item.amount;
                                                        } else {
                                                            sommeCagnotteEnAttente += wallet_item.amount;
                                                        }
                                                    });
                                                });
                                                */
                                                
                                            }
                                        });
                                    });
                                });
                            }
                        });
                    });
                });
            });
        });
    }

    render() {
        return (
            <div>
                <PageTitle title="Membres"/>
                <ElementTile >
                    {!this.state.selectedMember &&
                        <div>
                            <PageTitle title="Membre"/>

                            <BackgroundText style="mt-2" color="badColor">Selectionnez un membre pour voir les détails</BackgroundText>
                        </div>
                    }

                    {this.state.selectedMember &&
                        <div>
                            <PageTitle title={`Cagnotte de ${Utils.formatName(this.state.selectedMember)}`}/>

                            <BackgroundText color="badColor" style="m-2" onClick = {() => this.setState({selectedMember: null})}>
                                <p className="hover:cursor-pointer">Fermer la cagnotte de {Utils.formatName(this.state.selectedMember)}</p>
                            </BackgroundText>
                            <br/>

                            <div className="flex flex-wrap gap-4 m-4">
                                <StatTile title="Solde" value={Utils.formatMoney(this.state.selectedMember.solde)} icon={Balance} color="goodColor"/>
                                <StatTile title="En attente" value={Utils.formatMoney(this.state.selectedMember.creances)} icon={Balance} color="waitingColor"/>
                                <StatTile title="Solde corrigé" value={Utils.formatMoney(this.state.selectedMember.creances + this.state.selectedMember.solde)} icon={euro} color="neutralColor"/>
                            </div>


                            <h2>Ajouter une ligne</h2>
                            <form className="m-2" onSubmit={(e) => { 
                                e.preventDefault();
                                let description = document.getElementById("description").value;
                                let amount = document.getElementById("amount").value;
                                let file = document.getElementById("document").files[0];
                                WebService.addWallet(this.state.selectedMember.id, amount, description, file).then((response) => {
                                    if(response.status === 200) {
                                        let now = new Date();
                                        this.state.selectedMember.wallet.push({
                                            id: response.id,
                                            datetime: now,
                                            description: description,
                                            amount: amount,
                                            validated: false,
                                            document: response.document
                                        });
                                        this.setState({members: this.state.members});
                                    }
                                }
                                );

                            }}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>
                                                <div>
                                                    {/*<label htmlFor="description">Description</label>*/}
                                                    <input type="text" id="description" className="p-2 border-2 rounded border-middleBlue" placeholder="Description"></input>
                                                </div>
                                            </th>
                                            <th>
                                                <div>
                                                    {/*<label htmlFor="amount">Montant</label>*/}
                                                    <input type="float" id="amount" className="p-2 border-2 rounded border-middleBlue" placeholder="Montant"></input>
                                                </div>
                                            </th>
                                            <th>
                                                <div>
                                                    {/*<label htmlFor="document">Document</label>*/}
                                                    <input type="file" id="document" className="p-2 border-2 rounded border-middleBlue"></input>
                                                </div>
                                            </th>
                                            <th>
                                                <div>
                                                    <button type="submit" className="p-2 bg-darkBlue text-white rounded">Ajouter</button>
                                                </div>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>

                            <table className="w-full">
                                <thead className="m-2">
                                    <tr className="text-left text-white [&>*]:bg-darkBlue [&>*]:m-1 [&>*]:p-2 [&>*]:border-r-2">
                                        <th className="">Date</th>
                                        <th className="">Libellé</th>
                                        <th className="">Montant</th>
                                        <th className="">Statut</th>
                                        <th className="">Document</th>   
                                        <th className="">Actions</th>                                             
                                    </tr>
                                </thead>
                                <tbody>
                               



                                {this.state.selectedMember.wallet &&
                                    this.state.selectedMember.wallet.map((wallet_item) => {
                                        if(!(this.state.currentlyEditedWalletRow === wallet_item.id)) {
                                            return (
                                                <tr key={wallet_item.id} className="border-b-2 border-liteGray even:bg-liteGray  hover:cursor-pointer">
                                                        <td className="">{Utils.formatDateTime(wallet_item.datetime)}</td>
                                                        <td className="">{wallet_item.description}</td>
                                                        <td className=""><MoneyText amount={wallet_item.amount}/></td>
                                                        <td className="">
                                                        <div className="flex justify-center">    
                                                        {wallet_item.validated &&
                                                            <BackgroundText color="goodColor" style="p-1">Validé</BackgroundText>
                                                        }
                                                        {!wallet_item.validated &&
                                                            <BackgroundText color="waitingColor" style="p-1">En attente</BackgroundText>
                                                        }
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        {wallet_item.document &&
                                                            <a className="text-fontBlue text-center font-bold"
                                                            href={"/documents/"+wallet_item.document.name} target="_blank" rel="noreferrer">Télécharger</a>
                                                        }
                                                        {!wallet_item.document &&
                                                            <p className="text-neutralColor font-bold text-center">Aucun</p>
                                                        }
                                                    </td>
                                                    <td className="">
                                                        <BackgroundText color="neutralColor" style="p-1" onClick={() => {
                                                            this.setState({currentlyEditedWalletRow: wallet_item.id});
                                                            this.setState({rowEditionDescription: wallet_item.description});
                                                            this.setState({rowEditionAmount: wallet_item.amount});
                                                            this.setState({rowEditionValidation: wallet_item.validated});
                                                        }}>Editer
                                                        </BackgroundText>
                                                    </td>
                                                </tr>
                                            );
                                        } else {
                                            return (
                                                
                                                    <tr key={wallet_item.id} className="border-b-2 border-liteGray even:bg-liteGray  hover:cursor-pointer">
                                                            
                                                            <td className="">{Utils.formatDateTime(wallet_item.datetime)}</td>
                                                            {/*<td className="">{wallet_item.description}</td>*/}
                                                            <td className="">
                                                                <input type="text" className="p-2 border-2 rounded border-middleBlue" defaultValue={wallet_item.description}
                                                                onChange={(e) => {
                                                                    this.setState({rowEditionDescription: e.target.value});
                                                                }}
                                                                />
                                                            </td>

                                                            {/*<td className=""><MoneyText amount={wallet_item.amount}/></td>*/}
                                                            <td className="">
                                                                <input type="number" className="p-2 border-2 rounded border-middleBlue" defaultValue={wallet_item.amount}
                                                                onChange={(e) => {
                                                                    this.setState({rowEditionAmount: e.target.value});
                                                                }}
                                                                />
                                                            </td>
                                                            <td className="">
                                                            <div className="flex justify-center">    
                                                            {this.state.rowEditionValidation &&
                                                                <BackgroundText color="goodColor" style="p-1"
                                                                onClick={() => {
                                                                    this.setState({rowEditionValidation: false});
                                                                }}>Validé</BackgroundText>
                                                            }
                                                            {!this.state.rowEditionValidation &&
                                                                <BackgroundText color="waitingColor" style="p-1"
                                                                onClick={() => {
                                                                    this.setState({rowEditionValidation: true});
                                                                }}>En attente</BackgroundText>
                                                            }
                                                            </div>
                                                        </td>
                                                        <td className="text-center">
                                                            {wallet_item.document &&
                                                                <a className="text-fontBlue text-center font-bold"
                                                                href={"/documents/"+wallet_item.document.name} target="_blank" rel="noreferrer">Télécharger</a>
                                                            }
                                                            {!wallet_item.document &&
                                                                <p className="text-neutralColor font-bold text-center">Aucun</p>
                                                            }
                                                        </td>
                                                        <td className="">
                                                            <div>
                                                                <BackgroundText color="goodColor" style="p-1" onClick={() => {
                                                                    this.setState({currentlyEditedWalletRow: null});

                                                                    let file = null;
                                                                    WebService.updateWalletHistory(
                                                                        this.state.selectedMember.id, 
                                                                        wallet_item.id,
                                                                        this.state.rowEditionAmount,
                                                                        this.state.rowEditionValidation,
                                                                        this.state.rowEditionDescription,
                                                                        file
                                                                    ).then((response) => {
                                                                        if(response.status === 200) {
                                                                            wallet_item.description = this.state.rowEditionDescription;
                                                                            wallet_item.amount = this.state.rowEditionAmount;
                                                                            wallet_item.validated = this.state.rowEditionValidation;
                                                                            this.setState({members: this.state.members});
                                                                            
                                                                        }
                                                                    });
                                                                }}>Valider
                                                                </BackgroundText>
                                                                <BackgroundText color="badColor" style="p-1" onClick={() => {
                                                                    this.setState({currentlyEditedWalletRow: null});
                                                                }}>Annuler
                                                                </BackgroundText>
                                                            </div>
                                                        </td>
                                                        
                                                    </tr>
                                                
                                            );
                                        }
                                    })
                                }
                                </tbody>
                            </table>
                            {this.state.selectedMember.wallet.length === 0 &&
                                <BackgroundText color="neutralColor" style="m-2">Aucune transaction</BackgroundText>
                            }

                        </div>
                    }


                </ElementTile >


                <ElementTile >
                    <PageTitle title="Liste des membres"/>
                    {!this.state.loaded &&
                    <img src={loader} alt="Loading..." className="mx-auto"/>
                }
                    {this.state.loaded && 
                    <div>

                    <div className="flex flex-wrap gap-4 m-4">
                        <StatTile title={`Cotisants ${this.state.currentYear}`} value={this.state.totalCotisant} icon={UserIcon} color="waitingColor"/>
                        <StatTile title="Solde toutes cagnottes" value={Utils.formatMoney(this.state.sommeCagnotte)} icon={Balance} color="goodColor"/>
                        <StatTile title="En attente" value={Utils.formatMoney(this.state.sommeCagnotteEnAttente)} icon={Balance} color="waitingColor"/>
                        <StatTile title="Solde toutes cagnottes corrigés" value={Utils.formatMoney(this.state.sommeCagnotte +this.state.sommeCagnotteEnAttente)} icon={euro} color="neutralColor"/>
                    </div>

                    {/* Research inputs*/}
                    <div className="flex justify-between p-2 rounded">
                        <input type="text" className="p-2 border-2 rounded border-middleBlue" placeholder="Rechercher un membre" onChange={(e) => {
                            // filter members with CONTAINS
                            let filtered = this.state.members.filter((member) => {
                                let name = Utils.formatName(member).toLowerCase();
                                return name.includes(e.target.value.toLowerCase());
                            })
                            this.setState({membersFiltered: filtered});
                        }}/>
                    </div>

                    <table className="w-full">
                        <thead className="m-2">
                            {/* Align text on the left */}
                            <tr className="text-left text-white [&>*]:bg-darkBlue [&>*]:m-1 [&>*]:p-2 [&>*]:border-r-2">
                                <th className="">Nom</th>
                                <th className="">Téléphone</th>
                                <th className="">Email</th>
                                <th className="">Solde</th>
                                <th className="">Créance</th>
                                <th className="">Cotisation {this.state.currentYear}</th>
                            </tr>
                        </thead>
                        {/* Even rows are gray */}
                        <tbody>
                        {this.state.members &&
                            this.state.membersFiltered.map((member) => {
                                return (
                                    <tr key={member.id} className="
                                        border-b-2 
                                        border-liteGray 
                                        even:bg-liteGray  
                                        hover:text-fontBlue 
                                        hover:cursor-pointer
                                        "
                                        onClick={() => this.setState({selectedMember: member})}
                                        >
                                        
                                        <td className="">{Utils.formatName(member)}</td>
                                        <td className="">{Utils.formatPhone(member.phone)}</td>
                                        <td className="">{member.email}</td>
                                        <td>
                                            
                           
                                            <div>
                                                <MoneyText amount={member.solde}/>
                                            </div>
                                            
                                        </td>
                                        <td>
                                            <MoneyText amount={member.creances}/>
                                        </td>
                                        <td>
                                            {(member.isCurrentYearPaid == null || member.isCurrentYearPaid == undefined) &&
                                                <img src={loader} alt="loader" className="w-6 h-6"/>
                                            }
                                            <div className="flex justify-center">
                                                {member.isCurrentYearPaid &&
                                                
                                                    <BackgroundText color="goodColor" style="p-1" >Oui</BackgroundText>
                                                }
                                                {member.isCurrentYearPaid == false &&
                                                <div>
                                                    <BackgroundText color="badColor"  style="p-1">Non</BackgroundText>
                                                    <BackgroundText color="waitingColor" onClick={() => {
                                                        WebService.payFee(member.id, this.state.currentYear).then((response) => {
                                                            if(response.status === 200) {
                                                                member.isCurrentYearPaid = true;
                                                                this.setState({members: this.state.members});
                                                                this.setState({totalCotisant: this.state.totalCotisant + 1});
                                                            }
                                                        });
                                                    }}
                                                        style="p-1">Valider</BackgroundText>
                                                </div>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                
                                );
                            })
                        }

                        </tbody>
                    </table>
                    {!this.state.members &&
                        <p>Chargement...</p>    
                    } 
                </div>   
                }
                </ElementTile>
            </div>
        );
    }
}