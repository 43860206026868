// Desc: Profile page
// Path: src/Event.js

import React from "react";

import WebService from '../Service.js';

import {PageTitle, ExtraTable, StatTile, ElementTile, BackgroundText, MoneyText}  from "./Utils.js";

import UserIcon from "../images/utilisateur.png";
import event from "../images/event.png";
import Time from "../images/time.png";
import Balance from "../images/balance.png";
import euro from "../images/euro.png";

import Utils from "../Utils.js"

import loader from "../images/loader.gif";



// CSS
import "../App.css";



export default
class Budget extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            events: [],
            loading: true,
            editingRow: null,
            editingAmount: 0,
            selectedYear: new Date().getFullYear(),
        };
        let today = new Date();

        WebService.events().then((events) => {
            events.json().then((events) => {
                events = events.filter((event) => {
                    return new Date(event.endDatetime) < today;
                });

                // sort mort recent first
                events.sort((a, b) => {
                    return new Date(b.endDatetime) - new Date(a.endDatetime);
                });

                this.setState({events: events, loading: false});

                events.forEach((event) => {
                    WebService.isEventPaid(event.id).then((isPaid) => {
                        isPaid.json().then((isPaid) => {
                            event.isPaid = isPaid.isPaid;
                            this.setState({events: events});
                        });
                    });
                });
            });
        });
    }

    render() {

        if(this.state.loading) {
            return (
                <div>
                    <PageTitle title="Évènements"/>
                    <img src={loader} alt="Loading..." className="mx-auto"/>
                </div>
            )
        }
        return (
            <div>

                <PageTitle title="Évènements"/>

                <div className="flex flex-row gap-4">
                    <StatTile 
                        title={`Évènements ${this.state.selectedYear}`} 
                        icon={Time} 
                        value={this.state.events.filter((event) => {
                            return new Date(event.endDatetime).getFullYear() === this.state.selectedYear;
                        }).length}
                        color="goodColor"></StatTile>


                        <StatTile
                        title={`Participation moyenne ${this.state.selectedYear}`}
                        icon={Time}
                        value={(this.state.events.filter((event) => {
                            return new Date(event.endDatetime).getFullYear() === this.state.selectedYear;
                        }).map((event) => {
                            return event.participations.filter((participation) => {
                                return participation.status === "PRESENT" || participation.status === "PAID";
                            }).length;
                        }).reduce((a, b) => a + b, 0) / this.state.events.filter((event) => {
                            return new Date(event.endDatetime).getFullYear() === this.state.selectedYear;
                        }).length).toFixed(2) + " par évènement"}
                        color="goodColor"></StatTile>

                </div>
                <ElementTile>
                    <div>
                 
                        {!this.state.loading &&
                            <div>
                                <ExtraTable headers={["Date", "Nom", "Participants", "Défraiement individuel", "Payé", "Action"]}>
                                    {this.state.events.map((event) => {
                                        let participants = event.participations.filter((participation) => {
                                            return participation.status === "PRESENT" || participation.status === "PAID";
                                        }).length
                                        return (
                                            <tr className="text-left text-white bg-darkBlue m-1 p-2 border-r-2">
                                                <td>
                                                    {new Date(event.endDatetime).toLocaleDateString()}
                                                </td>
                                                <td>
                                                    <a href={"/event?id=" + event.id} target="_blank" className="hover:text-fontBlue">
                                                        {event.name}
                                                    </a>
                                                </td>
                                                <td>
                                                    {participants}
                                                </td>
                                                <td>
                                                    <MoneyText amount={event.remuneration}/>
                                                </td>
                                                <td>
                                                    
                                                    {(event.remuneration > 0 && participants > 0) &&
                                                        <div>
                                                        {event.isPaid &&
                                                            <BackgroundText color="goodColor" style="text-center ">
                                                                <p className="text-center">Payé</p>
                                                            </BackgroundText>
                                                        }
                                                        {!event.isPaid &&
                                                            <BackgroundText color="badColor" style="text-center ">
                                                                <p className="text-center">Non payé</p>
                                                            </BackgroundText>
                                                        }
                                                        </div>
                                                    }
                                                    {(event.remuneration == 0 || participants == 0) &&
                                                        <BackgroundText color="neutralColor" style="text-center ">
                                                            {event.remuneration == 0 &&
                                                                <p className="text-center">Aucun défraiement</p>
                                                            }
                                                            {participants == 0 &&
                                                                <p className="text-center">Aucun participant</p>
                                                            }
                                                        </BackgroundText>
                                                    }
                                                </td>
                                                <td>
                                                    {(!event.isPaid || event.remuneration == 0) &&
                                                        <BackgroundText color="neutralColor" style="text-center ">
                                                            <p className="text-center">Editer</p>
                                                        </BackgroundText>
                                                    }
                                                    {(event.remuneration > 0 && !event.isPaid && participants > 0) &&
                                                        <BackgroundText 
                                                            color="goodColor" 
                                                            style="text-center cursor-pointer" 
                                                            onClick={() => {
                                                                WebService.validateEventWallet(event.id, event.remuneration).then((response) => {
                                                                    if (response.status === 200) {
                                                                        event.isPaid = true;
                                                                        this.setState({events: this.state.events});
                                                                    }
                                                                });
                                                            }
                                                        }>
                                                            <p className="text-center">Valider le paiement</p>
                                                        </BackgroundText>
                                                    }
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </ExtraTable>
                                
                            </div>
                        }
                    </div>

                </ElementTile>  
            </div>
        )
    }
}