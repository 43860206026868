

import defaultProfileImage from "./images/guest.jpg";
import WebService from "./Service.js";

export default
class Utils {
    static formatPhone(phone) {
        // with spaces
        return phone.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5");
    }


    static formatMoney(amount) {
        if(!amount) {
            return "0,00 €";
        }
        // Handle spaces and commas and round to 2 decimals
        //return parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " €";

        // To euros and cents
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount);
    }

    static formatPhone(phone) {
        if(phone === null) {
            return "Non renseigné";
        }
        // with spaces
        return phone.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5");
    }
    

    static formatName(user) {
        let firstName = user.firstName;
        // to upper case the first letter
        firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);

        let lastName = user.lastName;
        // to upper case the name
        lastName = lastName.toUpperCase();

        return firstName + " " + lastName;
    }

    static formatDate(date) {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        return day + "/" + month + "/" + year;
    }

    static formatDateTime(date) {
        return new Date(date).toLocaleString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });

    }



    static events_presence_not_validated(events) {
        if (events === null) {
            return [];
        }
        // THe event must be terminated

        return events
        // filter events that have at least 1 participation with status ACCEPTED or PRESENT
        .filter((event) => {
            if(event.participations === null || event.participations === undefined) {
                return false;
            }
            return event.participations.filter((participation) => {
                return participation.status === "ACCEPTED" || participation.status === "PRESENT";
            }).length > 0;

        // filter events that are terminated
        }).filter((event) => {
            return new Date(event.endDatetime) < new Date();
        })
        // Remove the events with remuneration <= 0 where there is no more ACCEPTED 
        .filter((event) => {
            return event.remuneration > 0 || event.participations.filter((participation) => {
                return participation.status === "ACCEPTED";
            }).length > 0;
        });
        
    }

    static me() {
        return JSON.parse(localStorage.getItem("currentUser"));
    }

    // Association role labels
    static roleLabels(user) {
        switch (user.associationRole) {
            case "DESK":
                return "Bureau";
            case "PRESIDENT":
                return "Président";
            case "TREASURER":
                return "Trésorier";
            case "SECRETARY":
                return "Secrétaire";
            case "PHOTOGRAPHER":
                return "Photographe";
            case "MEMBER":
                return "Membre";
            case "GUEST":
                return "Invité";
            default:
                return "Aucun";
        }
    }


    // Check user picture
    static async profilePicture(user) {
        // remove cache
        if(!user || !user.picturePath) {
            return defaultProfileImage;
        }

        return await fetch(user.picturePath)  
        .then((response) => {
            if(response.status === 200) {
                return user.picturePath;
            } else {
                return defaultProfileImage;
            }
        });
    }
    

    static roleplayLabel(user) {
        let roleplayRoles = [];
        if(user.company && user.company !== "Aucun") { roleplayRoles.push(user.company); }
        if(user.rank  && user.regiment !== "Aucun") { roleplayRoles.push(user.rank); }
        if(user.regiment  && user.regiment !== "Aucun") { roleplayRoles.push(user.regiment); }
        return roleplayRoles.join(", ");

        
    }

    static roleImportance(user) {
        switch (user.associationRole) {
            case "DESK":
                return 1;
            case "PRESIDENT":
                return 2;
            case "TREASURER":
                return 3;
            case "SECRETARY":
                return 4;
            case "PHOTOGRAPHER":
                return 5;
            case "MEMBER":
                return 6;
            case "GUEST":
                return 7;
            case "IGNORE":
                return 8;
            default:
                return 9;
        }
    }
        
        


    static isMember(user) {
    return user && (user.role === "ADMIN" ||
    user.associationRole === "MEMBER" ||
    user.associationRole === "DESK"   ||
    user.associationRole === "PRESIDENT" ||
    user.associationRole === "TREASURER" ||
    user.associationRole === "SECRETARY" ||
    user.associationRole === "PHOTOGRAPHER" ||
    user.associationRole === "GUEST")
    }

    static isGuest(user) {
        return user && (user.associationRole === "GUEST")
    }

    static isOnlyGuest(user) {
        return (Utils.isMember(user) && !Utils.isGuest(user))
    }


    static isAdmin (user) {
        return user && (user.role === "ADMIN" ||
        user.associationRole === "DESK"   ||
        user.associationRole === "PRESIDENT" ||
        user.associationRole === "TREASURER" ||
        user.associationRole === "SECRETARY")
    }


    static isPhotographer(user) {
        return user && (user.role === "ADMIN" ||
        user.associationRole === "DESK"   ||
        user.associationRole === "PRESIDENT" ||
        user.associationRole === "TREASURER" ||
        user.associationRole === "SECRETARY" ||
        user.associationRole === "PHOTOGRAPHER")
    }


}