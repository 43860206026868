// Desc: Profile page
// Path: src/Assoc.js

import React from "react";

import WebService from '../Service.js';

import Utils from "../Utils.js";
import UtilsAdmin from "./Utils.js";
import OverAll from "./OverAll.js";
import Members from "./Members.js";
import Budget from "./Budget.js";
import Event from "./Event.js";

// CSS
import "../App.css";


// image logo
import cor_logo from "../images/corps_logo.png";

const Tabs = {
    Home: "home",
    Membres: "membres",
    Budget: "budget",
    Events: "events",
    Documents: "documents",
};

export default
class Assoc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: Tabs.Home,
        };
    }
    render() {
        return (
            <div className="bg-bgPage h-screen">
                <Navigation 
                    activeTab={this.state.activeTab}
                    setActiveTab={(tab) => this.setState({activeTab: tab})}
                    />
                <div className="p-4">
                    {this.state.activeTab === Tabs.Home &&
                        <OverAll />
                    }
                    {this.state.activeTab === Tabs.Membres &&
                        <Members />
                    }
                    {this.state.activeTab === Tabs.Budget &&
                        <Budget />
                    }
                    {this.state.activeTab === Tabs.Events &&
                        <Event />
                    }
                </div>
            </div>
        );
    }
}



class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div className="
                flex 
                flex-row 
                bg-navBackground
                ">

                <NavigationItem
                    name="Corps d'Observation du Rhin"
                    logo={cor_logo}
                    tab={Tabs.Home}
                    isActive={this.props.activeTab === Tabs.Home}
                    activateTab={() => this.props.setActiveTab(Tabs.Home)}
                    />
                <NavigationItem 
                    name="Membres"
                    tab={Tabs.Membres}
                    isActive={this.props.activeTab === Tabs.Membres}
                    activateTab={() => this.props.setActiveTab(Tabs.Membres)}
                    />
                <NavigationItem 
                    name="Budget"
                    tab={Tabs.Budget}
                    isActive={this.props.activeTab === Tabs.Budget}
                    activateTab={() => this.props.setActiveTab(Tabs.Budget)}
                    />
                <NavigationItem
                    name="Évènements"
                    tab={Tabs.Events}
                    isActive={this.props.activeTab === Tabs.Events}
                    activateTab={() => this.props.setActiveTab(Tabs.Events)}
                    />
                <NavigationItem
                    name="Documents"
                    tab={Tabs.Documents}
                    isActive={this.props.activeTab === Tabs.Documents}
                    activateTab={() => this.props.setActiveTab(Tabs.Documents)}
                    />
            </div>
        );
    }
}


class NavigationItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <div className={`
                    flex
                    flex-row
                    items-center
                    bg-navBackground
                    ${this.props.isActive ? "bg-navBackgroundHover" : "hover:bg-navBackgroundHover"}
                    p-2
                    `}
                onClick={this.props.activateTab}
                >

                {this.props.logo &&
                    <img src={this.props.logo} alt="logo" className="w-10 h-10" />
                }
                
                <p className={`
                    ${this.props.isActive ? "text-white" : "hover:text-white text-whiteFade console"}
                    hover:cursor-pointer
                    p-1
                    font-bold
                    `}>
                    {this.props.name}
                </p>


            </div>
        );
    }
}