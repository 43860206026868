import React from "react";
import "./App.css";
import "./index.css";
import Actualities from "./Actualities.js";
import Events from "./Events.js";
import Login from "./Login.js";
import Profile from "./Profile.js";
import Albums from "./Album.js";
import Association from "./Association.js";
import Administration from "./Administration.js";
import loader from "./images/loader.gif";
import WebService from "./Service.js";
import Orders from "./Orders.js";
import JoinUs from "./JoinUs.js";
import Logistique from "./Logistique.js";
import Assoc from "./admin/Assoc.js";

import {Page} from './App.js';
import Register from "./Register.js";

import borderImage from "./images/border.png";



export default
class Main extends React.Component {

    render() {
        switch (this.props.page) {
            case Page.Actualities:
                return (
                    <div>
                        <div>
                            {/*<img className="block ml-auto mr-auto w-full h-10" src={borderImage} alt="border" />*/}
                        </div>
                        <main className="min-h-screen">
                            <Actualities />
                        </main>
                    </div>
                );
            case Page.Events:
                return (
                    <div>
                        <div>
                        </div>
                        <main>
                            <Events 
                                setPage={this.props.setPage}
                                events={this.props.events}
                                getUser={this.props.getUser} 
                                />
                        </main>
                    </div>
                );
            case Page.Assoc:
                return (
                    <div>
                        <Assoc />
                    </div>
                );
            case Page.Albums:
                return (
                    <div>
                        <div>
                            {/*<img className="block ml-auto mr-auto w-full h-10" src={borderImage} alt="border" /> */}
                        </div>
                        <main className="min-h-screen">

                            <Albums />
                        </main>
                    </div>
                );
            case Page.Troc:
                return (
                    <main className="min-h-screen">
                        <h1>Zone de troc</h1>
                    </main>
                );
            case Page.Login:
                return (
                    <main className="min-h-screen">

                        <Login setActuPage={() => this.props.setPage(Page.Actualities)}
                               setRegisterPage={() => this.props.setPage(Page.Register)} 
                               setUser={() => this.props.setUser()}
                               />
                    </main>
                );
            case Page.Register:
                return (
                    <main className="min-h-screen">

                        <Register setLoginPage={() => this.props.setPage(Page.Login)} />
                    </main>
                );
            case Page.Budget:
                return (

                    <div>
                        <div>
                            <img className="block ml-auto mr-auto w-full h-10" src={borderImage} alt="border" />
                        </div>
                        <main className="min-h-screen bg-frenchBlue">

                            <Association />
                        </main>
                    </div>
                );
            case Page.Profile:
                return (
                    <main className="min-h-screen">
                        <img className="block ml-auto mr-auto w-full h-10" src={borderImage} alt="border" />
                        <Profile >
                        </Profile>
                    </main>
                 
                );
            case Page.Administration :
                return (
                    <div>
                        <div>
                            <img className="block ml-auto mr-auto w-full h-10" src={borderImage} alt="border" />
                        </div>
                        <main className="min-h-screen bg-frenchBlue">
                            <Administration />
                        </main>
                    </div>
                );
            case Page.Ticket:
                return (
                    <Ticket />
                );
            case Page.Commands:
                return (
                    <main className="min-h-screen">
                        <img className="block ml-auto mr-auto w-full h-10" src={borderImage} alt="border" />
                        <Orders />
                    </main>
                );
                case Page.JoinUs:
                return (
                    <main className="min-h-screen">
                        <JoinUs />
                    </main>
                );
            case Page.Logistique:
                return (
                    <main className="min-h-screen">
                        <Logistique />
                    </main>
                );
            default:
                return (
                    
                    <main className="min-h-screen">
                        {/*<img className="block ml-auto mr-auto w-full h-10" src={borderImage} alt="border" />*/}

                        <Actualities />
                    </main>
                );
        }
    }
}




// Ticket react
class Ticket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            message: "",
            messageColor: "text-frenchWhite"
        };
    }


    render() {
        return (
            <main>
            <div className="h-screen">
                <form className="bg-frenchBlack/75 inline-block text-frenchWhite text-left  border-frenchBrown border-2 rounded-md " onSubmit={(e) => {
                    e.preventDefault();
                    this.setState({isLoading: true, message: "Envoi en cours...", messageColor: "text-neutralColor"});
                    let content = document.getElementById("content").value;
                    let file = document.getElementById("file").files[0];
                    let request = WebService.sendTicket(content, file);
                    request.then((response) => {
                        if (response.status === 200) {
                            this.setState({isLoading: false, message: "Votre demande a bien été envoyée", messageColor: "text-goodColor"});
                        } else {
                            this.setState({isLoading: false, message: "Une erreur est survenue lors de l'envoi de votre demande", messageColor: "text-badColor"});
                        }
                    });
                }}>
                    <div>
                        <h3 className="text-center text-xl text-frenchWhite p-3 bg-frenchBrown">Signaler un bug ou formuler une demande aux administrateurs du site</h3>
                    </div>
                    <div className="p-5 flex flex-col">
                        <label htmlFor="content"><strong>Message</strong></label>
                        {/* A text area */}
                        <textarea className="p-1 rounded-sm text-frenchBlack h-60" type="text" id="content" name="content" placeholder="Veuillez décrire le problème rencontré ou votre demande" />
                    </div>
                    <div className="align-center p-5 justify-center">
                        {/* Pièce jointe */}
                        <label htmlFor="file" className=" rounded p-2 px-4"><strong>Ajouter une pièce jointe</strong></label>
                        <input type="file" id="file" name="file" className="" placeholder="Ajouter une pièce jointe"/>
                    </div>
                    <div className="align-center p-5 justify-center">
                        <button className="bg-frenchBrown rounded p-2 px-4" type="submit">
                            Envoyer
                        </button>
                    </div>
                    <div className="pb-2 font-bold">
                        <p className={"p-4 text-center "+this.state.messageColor}>
                            {this.state.isLoading && <img src={loader} alt="loading" className="block ml-auto mr-auto w-10 h-10" />}
                            {this.state.message}
                        </p>
                    </div>
                </form>
            </div>
        </main>
        );
    }
}