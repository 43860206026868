// Desc: Profile page
// Path: src/Budget.js

import React from "react";

import WebService from '../Service.js';

import {PageTitle, StatTile, ElementTile, BackgroundText, MoneyText, ExtraTable}  from "./Utils.js";

import UserIcon from "../images/utilisateur.png";
import Balance from "../images/balance.png";
import euro from "../images/euro.png";
import encaissement from "../images/encaissement.png";


import Utils from "../Utils.js"

import loader from "../images/loader.gif";

// CSS
import "../App.css";


export default
class Budget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            months: [],
            selectedMonth: null,
            comptaTags: [],
            loaded:false
        };


        WebService.getComptaTag().then((tags) => {
            tags.json().then((tags) => {
                this.setState({comptaTags: tags});
            });
        });

        WebService.getComptaMonth().then((months) => {
            months.json().then((months) => {
                //console.log(months);
                months = months.map((month) => {
                    month.items = [];
                    return month;
                });
                let incr = 0;
                months.forEach((month) => {
                    WebService.getComptaItem(month.id).then((items) => {
                        items.json().then((items) => {
                            let itemIncr = 0;
                            month.items = items;
                            
                            if(items.length == 0) {
                                incr++;
                                //console.log(`Months ${incr}/${months.length}`);
                                if (incr === months.length) {
                                    //console.log(months);                                
                                    this.setState({months: months});
                                    this.setState({loaded:true})

                                }

                            } else {
                                items.forEach((item) => {
                                WebService.getComptaTagItem(item.id).then((tags) => {
                                        tags.json().then((tags) => {
                                            item.tags = tags;
                                            itemIncr++;
                                            //console.log(`Items ${itemIncr}/${items.length}`);
                                            if (itemIncr === items.length) {
                                                incr++;
                                                //console.log(`Months ${incr}/${months.length}`);
                                                if (incr === months.length) {
                                                    //console.log(months);                                
                                                    this.setState({months: months});
                                                    this.setState({loaded:true})
                                                }
                                            }  
                                            
                                        });
                                    });
                                });
                            }
                            /*
                            month.items = items;
                            incr++;
                            if (incr === months.length) {
                                //console.log(months);                                
                                this.setState({months: months});
                            }
                            */
                        });
                    });
                });

                
            });
        });
    }

    render() {

        if(!this.state.loaded) {
            return (
                <div>
                    <PageTitle title="Budget"/>
                    <img src={loader} alt="Loading..." className="mx-auto"/>
                </div>
            )
        }

        console.log(this.state.months);
        // Array from 2024 to currentyear
        let years = Array.from({length: new Date().getFullYear() - 2023}, (v, k) => k + 2024);
        return (
            <div>
                <PageTitle title="Budget"/>
                <div className="flex flex-row gap-4">
                    <StatTile
                        title="Trésorerie"
                        icon={euro}
                        value={
                            Utils.formatMoney(
                                this.state.months
                                    .reduce((acc, month) => acc + month.items.reduce((acc, item) => acc + item.amount, 0), 0)
                            
                            )
                        }
                        color="goodColor"
                    />
                    {years.map((year) => {
                        return (
                            <StatTile
                                title={`Résultat ${year}`}
                                icon={Balance}
                                value={
                                    Utils.formatMoney(
                                        this.state.months
                                            .filter((month) => month.year === year)
                                            .reduce((acc, month) => acc + month.items.reduce((acc, item) => acc + item.amount, 0), 0)
                                    )
                                }
                                color="neutralColor"
                            />
                        );
                    })}
                </div>


                <ElementTile>

                    {!this.state.selectedMonth &&
                        <div>
                            <PageTitle title="Mois au détail"/>

                            <BackgroundText color="neutralColor">Sélectionnez un mois pour voir les détails</BackgroundText>
                        </div>
                    }
                    {this.state.selectedMonth &&
                        <div>
                            <PageTitle title={`Détails du mois ${this.state.selectedMonth.year}-${this.state.selectedMonth.month}`}/>

                            <BackgroundText color="badColor" style="cursor-pointer" onClick={() => this.setState({selectedMonth: null})}>Fermer le mois</BackgroundText>
                            <br/>
                            <br/>
                                <div className="flex flex-row gap-4">
                                    <StatTile
                                        title="Résultat"
                                        icon={Balance}
                                        value={
                                            Utils.formatMoney(
                                                this.state.selectedMonth.items
                                                    .reduce((acc, item) => acc + item.amount, 0)
                                            )}
                                        color="neutralColor"
                                        />
                                    <StatTile 
                                        title="Encaissement" 
                                        icon={euro} 
                                        value={
                                            Utils.formatMoney(
                                                this.state.selectedMonth.items
                                                    .filter((item) => item.amount > 0)
                                                    .reduce((acc, item) => acc + item.amount, 0)
                                            )}
                                        color="goodColor"
                                        /> 
                                    <StatTile
                                        title="Décaissement"
                                        icon={encaissement}
                                        value={
                                            Utils.formatMoney(
                                                this.state.selectedMonth.items
                                                    .filter((item) => item.amount < 0)
                                                    .reduce((acc, item) => acc + item.amount, 0)
                                            )}
                                        color="badColor"
                                        />
                                    

                                </div>

                            <br/>


                            <p>Ajouter un élément</p>
                            <form 
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    //console.log("submit");

                                    let description = e.target[0].value;
                                    let amount = parseFloat(e.target[1].value);
                                    let file = e.target[2].files[0];

                                    WebService.createComptaItem(this.state.selectedMonth.id, amount, description, file).then((res) => {
                                        res.json().then((res) => {
                                            //console.log(res);
                                            var newSelectedMonth = this.state.selectedMonth;
                                            res.tags = [];
                                            newSelectedMonth.items.push(res);
                                            this.setState({selectedMonth: newSelectedMonth});
                                        });
                                    });
                                }}
                                >
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>
                                                <div>
                                                    {/*<label htmlFor="description">Description</label>*/}
                                                    <input type="text" id="description" className="p-2 border-2 rounded border-middleBlue" placeholder="Description"></input>
                                                </div>
                                            </th>
                                            <th>
                                                <div>
                                                    {/*<label htmlFor="amount">Montant</label>*/}
                                                    <input type="float" id="amount" className="p-2 border-2 rounded border-middleBlue" placeholder="Montant"></input>
                                                </div>
                                            </th>
                                            <th>
                                                <div>
                                                    {/*<label htmlFor="document">Document</label>*/}
                                                    <input type="file" id="document" className="p-2 border-2 rounded border-middleBlue"></input>
                                                </div>
                                            </th>
                                            <th>
                                                <div>
                                                    <button type="submit" className="p-2 bg-darkBlue text-white rounded">Ajouter</button>
                                                </div>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                            <br/>
                        <ExtraTable headers={["Date", "Description", "Montant", "Tags", "Document", "Action"]}>
                            {this.state.selectedMonth.items.map((item) => {
                                return (
                                    <tr className="text-left" key={item.id}>
                                        <td>{new Date(item.date).toLocaleDateString()}</td>
                                        <td>{item.description}</td>
                                        <td><MoneyText amount={item.amount}/></td>
                                        <td>
                                            {/*Dropdown menu to select and add a tag*/}
                                            <select value="0" 
                                                onChange={(e)=> {
                                                    //console.log(e.target.value);
                                                    if (e.target.value === "0") {
                                                        return;
                                                    }
                                                    WebService.setComptaTagItem(parseInt(e.target.value), item.id).then((res) => {
                                                        res.json().then((comptaTagItem) => {
                                                            item.tags.push(comptaTagItem);
                                                            //console.log(comptaTagItem, item.tags);
                                                            this.setState({selectedMonth: this.state.selectedMonth});
                                                        });

                                                    });
                                                }}
                                            >
                                                <option>Choisir...</option>
                                                {this.state.comptaTags
                                                    .filter((tag) => !item.tags.map((t) => t.id).includes(tag.id))
                                                    .map((tag) => {
                                                    return (
                                                        <option value={tag.id}>{tag.name}</option>
                                                    );
                                                })}
                                            </select>
                                            {item.tags.map((tag) => {
                                                return (
                                                    <BackgroundText 
                                                        style="cursor-pointer"
                                                        color="neutralColor"
                                                        onClick={() => {
                                                            WebService.removeComptaTagItem(tag.id).then((res) => {
                                                                if (!res.ok) {
                                                                    return;
                                                                }
                                                                item.tags = item.tags.filter((t) => t.id !== tag.id);
                                                                this.setState({selectedMonth: this.state.selectedMonth});
                                                                
                                                            });
                                                        }}
                                                    >{tag.comptaTag.name}</BackgroundText>
                                                );
                                            })}

                                        </td>
                                        <td className="text-center"> 
                                            {item.document &&
                                                <a href={"/documents/"+item.document.name} target="_blank" className="font-bold text-darkBlue text-center">Télécharger</a>
                                            }
                                            {!item.document &&
                                                <BackgroundText color="neutralColor">Aucun document</BackgroundText>
                                            }
                                        </td>
                                        <td>
                                            <BackgroundText 
                                                style="cursor-pointer"
                                                color="badColor"
                                                onClick={() => {
                                                    WebService.deleteComptaItem(item.id).then((res) => {
                                                        if (!res.ok) {
                                                            return;
                                                        }
                                                        var newSelectedMonth = this.state.selectedMonth;
                                                        newSelectedMonth.items = newSelectedMonth.items.filter((i) => i.id !== item.id);
                                                        this.setState({selectedMonth: newSelectedMonth});
                                                    });
                                                }}
                                                >Supprimer</BackgroundText>
                                        </td>                                           
                                    </tr>
                                );
                            })}
                            
                        </ExtraTable>
    
                        </div>
         
                    }

                </ElementTile>


                <ElementTile>
                    <PageTitle title="Tous les mois"/>
                    <ExtraTable headers={["Année", "Mois", "Résultat", "Document", "Action"]}>
                        {this.state.months && this.state.months.map((month) => {
                            return (
                                <tr className="text-left" key={month.id}>
                                    <td>{month.year}</td>
                                    <td>{month.month}</td>
                                    <td><MoneyText amount={month.items.reduce((acc, item) => acc + item.amount, 0)}/></td>
                                    
                                    <td className="text-center">
                                        {month.document &&
                                            <a href={"/documents/"+month.document.name} target="_blank" className="font-bold text-darkBlue text-center">Télécharger</a>
                                        }
                                        {!month.document &&
                                            <BackgroundText color="neutralColor">Aucun document</BackgroundText>
                                        }

                                    </td>
                                    <td>
                                        <BackgroundText 
                                            color="neutralColor"
                                            style="cursor-pointer"
                                            onClick={() => {
                                                this.setState({selectedMonth: month});
                                            }}
                                            >Détails</BackgroundText>
                                        <BackgroundText 
                                            color="badColor"
                                            >Supprimer</BackgroundText>
                                    </td>
                                </tr>
                            );
                        })}
                    </ExtraTable>

                </ElementTile>



                <ElementTile>
                    <PageTitle title="Ajouter un nouveau mois"/>
                    <form 
                        className="flex flex-col"
                        onSubmit={(e) => {
                            e.preventDefault();
                            //console.log("submit");

                            let month = parseInt(e.target[0].value);
                            let year = parseInt(e.target[1].value);
                            let file = e.target[2].files[0];

                            WebService.createComptaMonth(month, year, file).then((res) => {
                                //console.log(res);
                                res.json().then((res) => {
                                    var newMonths = this.state.months;
                                    res.items = [];
                                    newMonths.push(res);
                                    this.setState({months: newMonths});
                                })
                            });
                        }}
                        >
                        <label className="">Mois</label>
                        <input type="number" className="p-2 border-2 rounded border-middleBlue" defaultValue={new Date().getMonth() + 1} />
                        <label className="">Année</label>
                        <input type="number" className="p-2 border-2 rounded border-middleBlue" defaultValue={new Date().getFullYear()} />
                        <label className="">Document</label>
                        <input type="file" className="p-2 m-1" />
                        <button className="bg-darkBlue text-white p-2 m-1">Ajouter</button>

                    </form>
                </ElementTile>



                <ElementTile>
                    <PageTitle title="Ajouter un tag"/>

                    <div>
                        <form 
                            className="flex flex-col"
                            onSubmit={(e) => {
                                e.preventDefault();

                                let name = e.target[0].value;

                                WebService.createComptaTag(name).then((res) => {
                                    res.json().then((res) => {
                                        var newTags = this.state.comptaTags;
                                        newTags.push(res);
                                        this.setState({comptaTags: newTags});
                                })})}}>
                            <label className="">Nom</label>
                            <input type="text" className="p-2 border-2 rounded border-middleBlue" />
                            <button className="bg-darkBlue text-white p-2 m-1">Ajouter</button>

                        </form>
                    </div>

                    <div>
                            {this.state.comptaTags.map((tag) => {
                                return (
                                    <div>
                                        <BackgroundText 
                                            color="neutralColor" 
                                            style="cursor-pointer"
                                            onClick={() => {
                                                WebService.deleteComptaTag(tag.id).then((res) => {
                                                    if (!res.ok) {
                                                        return;
                                                    }
                                                    var newTags = this.state.comptaTags;
                                                    newTags = newTags.filter((t) => t.id !== tag.id);
                                                    this.setState({comptaTags: newTags});
                                                });
                                            }}
                                        
                                        >{tag.name}</BackgroundText>
                                    </div>
                                );
                            })}
                    </div>


                </ElementTile>

            </div>
        )
    }
}



